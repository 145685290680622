(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("async.2018"));
	else if(typeof define === 'function' && define.amd)
		define("home", ["async.2018"], factory);
	else if(typeof exports === 'object')
		exports["home"] = factory(require("async.2018"));
	else
		root["home"] = factory(root["async.2018"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_async_2018__) {
return 