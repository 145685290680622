/*@flow*/

import {
	AsyncView
} from "../entry";

class Main extends AsyncView {
	type = `main`;
}

export default new Main();
